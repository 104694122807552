/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import './_theme-base';
@import './shared/side-nav.scss';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$roll-scroll-app-primary: mat.define-palette(mat.$blue-palette, 800);
$roll-scroll-app-accent: mat.define-palette(mat.$cyan-palette, 700);

// The warn palette is optional (defaults to red).
$roll-scroll-app-warn: mat.define-palette(mat.$red-palette, 800);

// Create the theme object (a Sass map containing all of the palettes).
$roll-scroll-app-theme: mat.define-light-theme($roll-scroll-app-primary, $roll-scroll-app-accent, $roll-scroll-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($roll-scroll-app-theme);

main {
  background-color: $dark-gray;
}

mat-card.page-card {
  background-color: $light-gray;
  mat-card-header {
    mat-card-title {
      color: $black;
    }
    mat-card-subtitle {
      color: $dark-gray;
    }
  }
  mat-card-content {
    mat-card.list-card {
      background-color: $dark-gray;
      mat-card-header {
        mat-card-title {
          color: $white;
        }
        mat-card-subtitle {
          color: $light-gray;
        }
      }
      mat-card-content {
        overflow: hidden;
      }
      mat-card-actions {
        flex-wrap: wrap;
        button {
          margin: 4px 8px;
        }
      }
    }
    mat-card.list-card:hover {
      box-shadow:
        inset 0 0 70px $light-gray,         /* inner white */
        inset 20px 0 80px $dark-gray,    /* inner left */
        inset -20px 0 80px $mid-gray,    /* inner right */
        inset 20px 0 300px $dark-gray,   /* inner left */
        inset -20px 0 300px $mid-gray,   /* inner right */
        0 0 25px $light-gray,                /* outer white */
        -10px 0 40px $dark-gray,         /* outer left */
        10px 0 40px $mid-gray;           /* outer right */
    }
  }
}
