@use '@angular/material' as mat;
@import '@angular/material/theming';
@import './colors.scss';
@import '../themes/shared/index';
@include mat.core();

@mixin theming($theme, $typography: null) {
  @include mat.all-component-themes($theme);
  @if $typography {
    @include mat.all-component-typographies($typography);
  }
  /* include mixin from component, if desired*/
}

* {
  font-family: 'Helvetica', 'Arial', sans-serif;
}

/*
Side nav colors
*/
:root {
  font-size: 16px;
  font-family: 'Open Sans';
  --text-primary: #b6b6b6;
  --text-secondary: #ececec;
  --bg-primary: #23232e;
  --bg-secondary: #141418;
  --transition-speed: 600ms;
}

.nav-link {
  display: flex;
  align-items: center;
  height: 5rem;
  color: var(--text-primary);
  text-decoration: none;
  filter: grayscale(100%) opacity(0.7);
  transition: var(--transition-speed);
}

.nav-link:hover {
  filter: grayscale(0%) opacity(1);
  background: var(--bg-secondary);
  color: var(--text-secondary);
}

.nav-link-active {
  filter: grayscale(0%) opacity(1);
  color: #00aa44;
}

.nav-link-active:hover {
  filter: grayscale(0%) opacity(1);
  color: #00aa44;
}


.fa-primary {
  color: #ff7eee;
}

.fa-secondary {
  color: #df49a6;
}

body {
  margin: 0 !important;
  overflow: hidden;
}

$grid-columns: 12;
$grid-gutter: 20px;

.row {
  display: grid;
  grid-template-columns: repeat($grid-columns, 1fr); /*cols x freespace*/
  grid-gap: $grid-gutter;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  &.dark-backdrop {
    background: #000;
    opacity: 0.9 !important;
  }
}

mat-card.page-card {
  router-outlet + * {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  form {
    display: contents;
  }
  mat-card-content {
    flex-grow: 1;
    overflow: overlay;
    mat-card.list-card {
      margin: 6px;
      opacity: 0.85;
      transition: all 0.2s ease-in-out;
      mat-card-header {
        div.mat-card-header-text {
          width: 100%;
        }
        mat-card-title {
          display: flex;
          div.left,
          div.right {
            flex-basis: 50%;
          }
          div.right {
            text-align: right;
          }
        }
        mat-card-content {
          overflow: hidden;
        }
      }
    }
    mat-card.list-card:hover {
      opacity: 1;
      transform: scale(1.008);
    }
  }
  mat-card-actions {
    width: 100%;
    display: flex;
    div.left,
    div.right {
      flex-basis: 50%;
    }
    div.right {
      text-align: right;
    }
  }
}

/* Build Bootstrap's Grid */
@for $i from 1 through $grid-columns {
  .col-xs-#{$i} {
    grid-column: span ($i);
  }
}
@for $i from 1 through $grid-columns {
  .col-sm-#{$i} {
    grid-column: span ($i);
  }
}
@for $i from 1 through $grid-columns {
  .col-md-#{$i} {
    grid-column: span ($i);
  }
}
@for $i from 1 through $grid-columns {
  .col-lg-#{$i} {
    grid-column: span ($i);
  }
}
@for $i from 1 through $grid-columns {
  .col-xl-#{$i} {
    grid-column: span ($i);
  }
}

/* Bootstrap grid break points */
@media (max-width: 576px) {
  @for $i from 1 through $grid-columns {
    .col-xs-#{$i} {
      grid-column: span ($i);
    }
  }
  /* helper classes */
  .hidden-xs {
    display: none;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  @for $i from 1 through $grid-columns {
    .col-sm-#{$i} {
      grid-column: span ($i);
    }
  }
  /* helper classes */
  .hidden-sm {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  @for $i from 1 through $grid-columns {
    .col-md-#{$i} {
      grid-column: span ($i);
    }
  }
  /* helper classes */
  .hidden-md {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  @for $i from 1 through $grid-columns {
    .col-lg-#{$i} {
      grid-column: span ($i);
    }
  }
  /* helper classes */
  .hidden-lg {
    display: none;
  }
}
@media (min-width: 1200px) {
  @for $i from 1 through $grid-columns {
    .col-xl-#{$i} {
      grid-column: span ($i);
    }
  }
  /* helper classes */
  .hidden-xl {
    display: none;
  }
}

article.lib-page-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include respond-to('small') {
    mat-card.page-card {
      width: 90%;
      height: calc(100% - 64px - 5rem);
      justify-content: center;
      align-items: center;
      margin-top: -5rem;
      mat-card-header {
        justify-content: center;
      }
      mat-card-content {
        width: 100%;
        justify-content: center;
        mat-form-field {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
  @include respond-to('medium') {
    mat-card.page-card {
      width: 80%;
      height: 80%;
      overflow: overlay;
      justify-content: center;
      align-items: center;
      mat-card-header {
        justify-content: center;
      }
      mat-card-content {
        box-sizing: border-box;
        width: 100%;
        justify-content: center;
        padding: 0px 22px;
        mat-form-field {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
  @include respond-to('large') {
    mat-card.page-card {
      width: 78%;
      height: 78%;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      mat-card-header {
        justify-content: center;
      }
      mat-card-content {
        width: 100%;
        justify-content: center;
        mat-form-field {
          width: 100%;
          justify-content: center;
        }
      }
    }
  }
}

.short-logo-image {
  width: 52px;
  height: auto;
  border: 1px solid white;
  border-radius: 6px;
}

.short-logo-adjustments {
  margin-top: 8px;
}

.app-toolbar-filler {
  flex: 1 1 auto;
}

.cursor-pointer {
  cursor: pointer;
}
