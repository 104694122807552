.main-page {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

$grid-columns:12;
$grid-gutter:20px;

.row {
  display: grid;
  grid-template-columns: repeat($grid-columns, 1fr);/*cols x freespace*/
  grid-gap: $grid-gutter;
}

/* Build Bootstrap's Grid */
@for $i from 1 through $grid-columns {
  .col-xs-#{$i} {
    grid-column: span ($i);
  }
}
@for $i from 1 through $grid-columns {
  .col-sm-#{$i} {
    grid-column: span ($i);
  }
}
@for $i from 1 through $grid-columns {
  .col-md-#{$i} {
    grid-column: span ($i);
  }
}
@for $i from 1 through $grid-columns {
  .col-lg-#{$i} {
    grid-column: span ($i);
  }
}
@for $i from 1 through $grid-columns {
  .col-xl-#{$i} {
    grid-column: span ($i);
  }
}

/* Bootstrap grid break points */
@media (max-width: 576px) {
  @for $i from 1 through $grid-columns {
    .col-xs-#{$i} {
      grid-column: span ($i);
    }
  }
 /* helper classes */
  .hidden-xs {display:none;}
}
@media (min-width:576px) and (max-width: 768px) {
  @for $i from 1 through $grid-columns {
    .col-sm-#{$i} {
      grid-column: span ($i);
    }
  }
 /* helper classes */
  .hidden-sm {display:none;}
}
@media (min-width:768px) and (max-width: 992px) {
  @for $i from 1 through $grid-columns {
    .col-md-#{$i} {
      grid-column: span ($i);
    }
  }
 /* helper classes */
  .hidden-md {display:none;}
}
@media (min-width:992px) and (max-width: 1200px) {
  @for $i from 1 through $grid-columns {
    .col-lg-#{$i} {
      grid-column: span ($i);
    }
  }
 /* helper classes */
  .hidden-lg {display:none;}
}
@media (min-width:1200px) {
  @for $i from 1 through $grid-columns {
    .col-xl-#{$i} {
      grid-column: span ($i);
    }
  }
 /* helper classes */
  .hidden-xl {display:none;}
}

