
.navbar {
  background-color: var(--bg-primary);
  transition: width 300ms ease;
}

.navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.nav-item {
  cursor: pointer;
  width: 100%;
}
/*
.nav-item:last-child {
  margin-top: auto;
}
*/

.link-text {
  display: none;
  margin-left: 1rem;
}

.nav-link svg {
  width: 2rem;
  min-width: 2rem;
  margin: 0 1.5rem;
}

li.nav-item .nav-link .material-icons {
  width: 2rem;
  min-width: 2rem;
  margin: 0 1.5rem;
}

.fa-primary,
.fa-secondary {
  transition: var(--transition-speed);
}

.logo {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
  text-align: center;
  color: var(--text-secondary);
  background: var(--bg-secondary);
  font-size: 1.5rem;
  letter-spacing: 0.3ch;
  width: 100%;
}

.logo svg {
  transform: rotate(0deg);
  transition: 300ms;
}

.logo-text {
  display: inline;
  position: absolute;
  left: -999px;
  transition: var(--transition-speed);
  font-size: 1rem;
}

.navbar:hover .logo svg {
  transform: rotate(-180deg);
}

@media only screen and (max-height: 800px) {
  .nav-link {
    height: 4rem;
  }
}

/* Small screens */
@media only screen and (max-width: 600px) {
  .navbar {
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 5rem;
    z-index: 999;
  }

  .logo {
    display: none;
  }

  .navbar-nav {
    flex-direction: row;
  }

  .nav-link {
    justify-content: center;
    flex-direction: column;
    .link-text {
      display: inline;
      margin: 4px 0 0 0;
    }
  }

  main {
    margin: 0;
  }
}

/* Large screens */
@media only screen and (min-width: 600px) {
  .navbar {
    width: 5rem;
    height: calc(100vh - 64px);
  }

  .navbar:hover {
    width: 16rem;
  }

  .navbar:hover .link-text {
    display: inline;
    transition: var(--transition-speed);
  }

  .navbar:hover .logo svg {
    margin-left: 11rem;
  }

  .navbar:hover .logo-text {
    left: 0px;
  }
}


.theme-icon {
  display: none;
}

.dark #darkIcon {
  display: block;
}

.light #lightIcon {
  display: block;
}

.solar #solarIcon {
  display: block;
}
