$white: #FFFFFF;
$light-gray: #C3C5C8;
$mid-gray: #8A8B8C;
$dark-gray: #4E4E4E;
$black: #000000;

$red: #FF0000;
$green: #00FF00;
$blue: #0000FF;
$rs-blue: #1565C0;
$rs-orange: #FF7A20;
$rs-green: #00AA44;
$orange: #FFA500;
$purple: #4B0082;
